import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import Button from 'react-bootstrap/Button'
import { MdFileDownload } from 'react-icons/md'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import PropTypes from 'prop-types'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

function ReportList (props) {
  function renderSuggestionsBootstrapTable () {
    const columns = [
      {
        dataField: 'group_id',
        isDummyField: true,
        text: 'COG ID',
        // eslint-disable-next-line react/display-name
        formatter: (_cellContent, row, _rowIndex) => {
          return <div>{row.group_id}</div>
        },
        headerStyle: () => {
          return { width: '140px' }
        },
        sort: true
      },
      {
        dataField: 'order_id',
        isDummyField: true,
        text: 'IGM Order ID',
        // eslint-disable-next-line react/display-name
        formatter: (_cellContent, row, _rowIndex) => {
          return <div>{row.order_id}</div>
        },
        headerStyle: () => {
          return { width: '140px' }
        },
        sort: true
      },
      {
        dataField: 'subject_id',
        isDummyField: true,
        text: 'IGM Subject ID',
        // eslint-disable-next-line react/display-name
        formatter: (_cellContent, row, _rowIndex) => {
          return <div>{row.subject_id}</div>
        },
        headerStyle: () => {
          return { width: '140px' }
        },
        sort: true
      },
      {
        dataField: 'subject_name',
        text: 'Subject',
        headerStyle: () => {
          return { width: '200px' }
        },
        sort: true
      },
      {
        dataField: 'date_created',
        text: 'Date Created',
        headerStyle: () => {
          return { width: '200px' }
        },
        sort: true
      },
      {
        dataField: 'amended_report',
        text: 'Amended',
        headerStyle: () => {
          return { width: '100px' }
        },
        sort: true
      },
      {
        dataField: 'disease_info',
        text: 'Disease Info',
        sort: true
      },
      {
        dataField: 'type',
        text: 'Type',
        sort: true
      },
      {
        dataField: 'cog_download_link',
        isDummyField: true,
        text: 'Redacted Report',
        // eslint-disable-next-line react/display-name
        formatter: (_cellContent, row, _rowIndex) => {
          if (row.cog_download_link === null || row.cog_download_link === undefined) {
            return <div>NA</div>
          } else {
            return (
                  <div>
                    {
                        <OverlayTrigger
                          key="left"
                          placement="left"
                          overlay={
                            <Tooltip id={'tooltip-left'}>
                              Download Redacted Report
                            </Tooltip>
                          }
                        >
                        <a href={row.cog_download_link} download="CogReport" target='_blank' rel="noopener noreferrer">
                          <Button id="downloadButton" className="cog-report-download-button" variant="success"><MdFileDownload /></Button>
                        </a>
                      </OverlayTrigger>
                    }
                    </div>
            )
          }
        },
        headerStyle: () => {
          return { width: '100px' }
        }
      },
      {
        dataField: 'download_link',
        isDummyField: true,
        text: 'Full Report',
        // formatExtraData: this.state.chosenTerms,
        // eslint-disable-next-line react/display-name
        formatter: (_cellContent, row, _rowIndex) => {
          return (
                <span>
                  {
                      <OverlayTrigger
                        key="left"
                        placement="left"
                        overlay={
                          <Tooltip id={'tooltip-left'}>
                            Download Full Report
                          </Tooltip>
                        }
                      >
                      <a href={row.download_link} download="Report" target='_blank' rel="noopener noreferrer">
                        <Button id="downloadButton" className="report-download-button" variant="success"><MdFileDownload /></Button>
                      </a>
                    </OverlayTrigger>
                  }
                </span>
          )
        },
        headerStyle: () => {
          return { width: '40px' }
        }
      }
    ]

    const rowStyle = (row, rowIndex) => {
      const style = {}

      if (rowIndex % 2 === 0) {
        style.backgroundColor = '#ffffff'
      } else {
        style.backgroundColor = '#f5f5f5'
      }

      return style
    }

    return (
          <BootstrapTable bootstrap4 className="report-list-table" keyField='id' data={ props.reportList } columns={ columns } /* expandRow={ expandRow } */ rowStyle={ rowStyle }/>
    )
  }

  return (
        <div className="report-list-table">
            {(props.reportList && props.reportList.length > 0) &&
                renderSuggestionsBootstrapTable()
            }

        </div>
  )
}

ReportList.propTypes = {
  reportList: PropTypes.array
}

export default ReportList
