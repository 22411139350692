import { useReducer, useState, useEffect } from 'react'
import { Amplify, Auth, Hub } from 'aws-amplify'

/***
  Loads of stuff happening in this file so it makes sense to put in loads of comments.
  This React custom hook is used to encapsulate all the ugliness that is user authentication
  via AWS Cognito by way of AWS Amplify.
***/

// The Amplify SDK needs to be configured before everything happens so run it in the global
// scope. Curiously, we need to define a userPoolId and userPoolWebClientId even though those
// aren't used by our application at all. Seems like a bug in Amplify, although to be fair no
// one uses Cognito Identity Pools with Developer Authentication. (But we have to be cause of CTEP and
// its ancient SAML implementation.)
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: 'us-east-2',
    userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  }
})

// The Ampily Hub system which gets notifications from their auth process fits in pretty nicely
// with React reducers. Thus we define a reducer function here to handle managing our user state.
// This is function should always get call from the Hub events below.
const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USER_DATA_INIT':

      // console.log("In FETCH_USER_DATA_INIT");
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case 'FETCH_USER_DATA_SUCCESS':

      // console.log("In FETCH_USER_DATA_SUCCESS");
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload.user
      }
    case 'FETCH_USER_DATA_FAILURE':
      // console.log("In FETCH_USER_DATA_FAILURE");
      return { ...state, isLoading: false, isError: true }
    case 'RELOAD_PAGE':
      // console.log("In RELOAD_PAGE");
      window.location.href = window.location.protocol + '//' + window.location.host
      break
    default:
      throw new Error()
  }
}

// Now we define our public endpoint to this code, a custom hook. The state object that is returned
// is a mix of data to control auth processing/loading, the data returned by CTEP (the cognito id and token)
// as well as the user name and email we get from our /associate_sso_user REST call.
const useAmplifyAuth = () => {
  // Setup our initial state
  const initialState = {
    isLoading: true,
    isError: false,
    id: null,
    token: null,
    userName: null,
    emailAddress: null
  }

  // And register the reducer function we defined above
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState)

  // As well as local state variable to trigger processing
  const [triggerFetch, setTriggerFetch] = useState(false)

  // This hook is stolen, err, borrowed, almost verbatium from here:
  // https://www.rockyourcode.com/custom-react-hook-use-aws-amplify-auth/
  useEffect(() => {
    let isMounted = true

    const fetchUserData = async () => {
      if (isMounted) {
        dispatch({ type: 'FETCH_USER_DATA_INIT' })
      }
      try {
        if (isMounted) {
          // Some important magic here. NORMALLY, the result from Auth.currentAuthenticatedUser
          // would have everything we need. BUT, because we are using Developer Auth to deal with the
          // CTEP SSO system we need to make a second call...
          const data = await Auth.currentAuthenticatedUser()
          if (data) {
            // ...which is to /associate_sso_user. Besides doing some backend stuff to put new users
            // into the Cognito Identity Pool and move reports that belong to them in S3, it also
            // returns their name and email.
            fetch(process.env.REACT_APP_API_URI + '/associate_sso_user', {
              method: 'GET',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                Authorization: data.token
              }
            }).then(result => result.json())
              .then(resultJson => {
              // console.log(result_json);

                data.name = resultJson.name
                data.email = resultJson.email

                dispatch({
                  type: 'FETCH_USER_DATA_SUCCESS',
                  payload: { user: data }
                })
              }).catch(e => {
                console.log('Caught exception in associate_sso_user')
                console.log(e)
                dispatch({ type: 'FETCH_USER_DATA_FAILURE' })
              })
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: 'FETCH_USER_DATA_FAILURE' })
        }
      }
    }

    // Setup the AWS Amplify Hub listener
    const HubListener = () => {
      Hub.listen('auth', data => {
        const { payload } = data
        onAuthEvent(payload)
      })
    }

    // ...and call this event handler when we get a signIn event.
    const onAuthEvent = payload => {
      switch (payload.event) {
        case 'signIn':
          if (isMounted) {
            setTriggerFetch(true)
            // console.log('signed in')
          }
          break
        default:
          console.log('In hub event: ' + payload.event)
      }
    }

    // Startup up the functions that we have set up
    HubListener()
    fetchUserData()

    // And then return the clean up as the result of our Hook. This will be called by React when the
    // component gets unmounted so we clean up the Hub stuff after ourselves.
    return () => {
      Hub.remove('auth')
      isMounted = false
    }
  }, [triggerFetch])

  // While we are here, define a signout function that we can pass to whereven the logout button is
  // in the UI. That way he doesn't know how to logout, he can just call the method.
  const handleSignout = async () => {
    try {
      console.log('signed out')
      await Auth.signOut()
      setTriggerFetch(false)
      dispatch({ type: 'RELOAD_PAGE' })
    } catch (error) {
      console.error('Error signing out user ', error)
    }
  }

  return { state, handleSignout }
}

export default useAmplifyAuth
