import React, { useEffect, useState, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import useAmplifyAuth from './AmplifyAuthHook'
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'

const useModal = (initialMode = false) => {
  const [show, setShow] = useState(initialMode)
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [callbackObj, setCallbackObj] = useState({ callback: () => {} }) // can't save functions as state objects
  const setCallback = useCallback(cb => { setCallbackObj({ callback: cb }) })
  const open = useCallback(() => setShow(true))
  const close = useCallback(() => setShow(false))
  return {
    show,
    setShow,
    title,
    setTitle,
    body,
    setBody,
    open,
    close,
    callback: callbackObj.callback, // return the callback
    setCallback
  }
}
useModal.propTypes = {
  initialMode: PropTypes.bool
}

const Order = () => {
  const [searchOrder, setSearchOrder] = useState(undefined)
  const [searchResponse, setSearchResponse] = useState('')
  const [searchResponseVariant, setSearchResponseVariant] = useState('light')
  const [showSearchResponseAlert, setShowSearchResponseAlert] = useState(false)
  const [count, setCount] = useState(0)
  const [validationCode, setValidationCode] = useState('')
  const [validationCount, setValidationCount] = useState(0)
  const [validateEmailCodeMessage, setValidateEmailCodeMessage] = useState('')
  const validateCodeModal = useModal()

  const {
    state: { user }
  } = useAmplifyAuth()

  const searchForMissingOrder = () => {
    console.log('In searchForMissingOrder')

    const endpoint = process.env.REACT_APP_API_URI + '/search_for_missing_order'
    const method = 'POST'

    const queryData = JSON.stringify({
      group_order_id: searchOrder,
      email: user.email
    })

    fetch(endpoint, {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.token
      },
      body: queryData
    }).then(response => {
      return response.json()
    }).then(data => {
      const apiOutput = JSON.parse(data.output)
      console.log(apiOutput)
      setSearchResponse(apiOutput.message)
      setShowSearchResponseAlert(true)
      setSearchResponseVariant('danger')

      if (apiOutput.prompt_for_verification) {
        setShowSearchResponseAlert(true)
        setSearchResponseVariant('warning')
        validateCodeModal.setTitle('Fix Email Mismatch')
        validateCodeModal.setBody('A five digit verification code was sent to the email address designated in the original order. Copy that code into the box below and click Validate Code to set the order email to your CTEP one.')
        validateCodeModal.open()
      }
    }).catch(error => {
      // TODO: Set failure status here
      console.log('Caught an error')
      console.error(error)
    })
  }

  const validateCodeAndFixEmail = () => {
    console.log('In validateCodeAndFixEmail')

    const endpoint = process.env.REACT_APP_API_URI + '/validate_email_code'
    const method = 'POST'

    const queryData = JSON.stringify({
      verification_number: validationCode,
      login_email_address: user.email
    })

    fetch(endpoint, {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.token
      },
      body: queryData
    }).then(response => {
      return response.json()
    }).then(data => {
      console.log('######')
      console.log(data)
      const apiOutput = JSON.parse(data.output)
      console.log(apiOutput)

      if (apiOutput.success) {
        setShowSearchResponseAlert(true)
        setSearchResponseVariant('success')
        setSearchResponse(apiOutput.message)
        validateCodeModal.close()
      } else {
        console.log(apiOutput.message)
        setValidateEmailCodeMessage(apiOutput.message)
      }
    }).catch(error => {
      // TODO: Set failure status here
      console.log('Caught an error')
      console.error(error)
    })
  }

  const handleSearchFieldChange = e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())

    // We want to keep the values the user types into the search box
    // so put them in the state. That, along with incrementing the count, will trigger
    // the useEffect hook to go pull the new data.
    setSearchOrder(formDataObj.orderSearchBox)
    setCount(count + 1)
  }

  useEffect(() => {
    if (searchOrder) {
      searchForMissingOrder()
    }
  }, [searchOrder, count])

  const handleValidationCodeChange = (e) => {
    setValidateEmailCodeMessage('')

    console.log('In handleValidationCodeChange')
    console.log(e)
    e.preventDefault()
    const formData = new FormData(e.target)
    const formDataObj = Object.fromEntries(formData.entries())

    console.log('formDataObj')
    console.log(formDataObj)

    setValidationCode(formDataObj.validationCodeEditBox)
    setValidationCount(count + 1)
  }

  useEffect(() => {
    if (validationCode) {
      console.log('Sending validation code to server')
      console.log(validationCode)
      validateCodeAndFixEmail()
    }
  }, [validationCode, validationCount])

  const fooModal = (
    <Modal show={validateCodeModal.show} onHide={validateCodeModal.close}>
      <Modal.Header>
        <Modal.Title>{validateCodeModal.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {validateCodeModal.body}
          <Form onSubmit={handleValidationCodeChange}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                placeholder="Number Here"
                autoFocus
                name='validationCodeEditBox'
              />
            </Form.Group>
            <Button className='form-button' variant="secondary" onClick={validateCodeModal.close}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Validate Code
            </Button>
          </Form>
      </Modal.Body>
      <Modal.Footer>
        {validateEmailCodeMessage}
      </Modal.Footer>
    </Modal>
  )

  const placeholder = 'Ex: 912345'

  return <div>
    <div className='page-buttons'>
      <span>
        <Form onSubmit={handleSearchFieldChange}>
          <Form.Group className="mb-3">
            <Form.Text className="fw-bold">
              Not seeing the patient you are searching for? Enter the patient ID and click submit to check for an email mismatch with the order.
            </Form.Text>
            <Form.Control type="text" placeholder={placeholder} name='orderSearchBox'/>
            <Form.Text className="fw-bold">
              <Alert variant={searchResponseVariant} show={showSearchResponseAlert}>
                {searchResponse}
              </Alert>
            </Form.Text>
          </Form.Group>
          <Button variant="primary" type="submit">
            Search
          </Button>
        </Form>
      </span>
    </div>
    <div>
      {fooModal}
    </div>
  </div>
}

export default Order
