import React, { useState, useEffect } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import TopNavBar from './TopNavBar'
import useAmplifyAuth from './AmplifyAuthHook'
import Spinner from 'react-bootstrap/Spinner'
import InputGroup from 'react-bootstrap/InputGroup'
import Order from './Order'
import ReportList from './ReportList'
import Alert from 'react-bootstrap/Alert'

const ENDPOINT_BASE = process.env.REACT_APP_API_URI

function App () {
  const [loading, setLoading] = useState(false)
  const [reportList, setReportList] = useState([])

  // The custom useAmplifyAuth hook encapsulates the logic for Amplify Hub and for associating the
  // SSO user.
  const {
    state: { user },
    handleSignout
  } = useAmplifyAuth()

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('CTEP SSO timeout expired. Forcing signout.')
      handleSignout()
    }, 900000) // 15 minutes
    return () => clearTimeout(timer)
  }, [])

  // function loadReportsForUser () {
  React.useEffect(() => {
    if (user !== undefined && user !== null) {
      setLoading(true)

      const endPoint = '/get_reports_for_clinician'

      fetch(ENDPOINT_BASE + endPoint, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: user.token
        }
      }).then(result => result.json())
        .then(resultJson => {
          console.log('################')
          console.log(resultJson)

          setReportList(resultJson.report_list)
          setLoading(false)
        }).catch(e => {
          console.log('Caught exception in loadReportsForUser')
          console.log(e)
        })
    }
  }, user)

  return (
    <div>
      <div>
        <TopNavBar user={user} handleSignout={handleSignout}/>
      </div>
      <div>
        {user &&
        <Alert variant='primary'>
          CTEP Email: <b>{user.email}</b>
        </Alert>
        }
      </div>
      <div>
        {user &&
          <Order/>
        }
      </div>
      <div className="search-holder">
        <InputGroup className="mb-3">
          {loading &&
            <div className="report-list-spinner-holder">
                <Spinner animation="border" />
            </div>
          }

        </InputGroup>
      </div>
      <div>
      {user &&
        <ReportList reportList={reportList} />
      }
      </div>
    </div>
  )
}

export default App
