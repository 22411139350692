import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import CTEPLoginButton from './CTEPLoginButton'

function TopNavBar (props) {
  return (
        <Navbar className="justify-content-between" bg="light" variant="light" expand="lg">
          <Navbar.Brand className="navbar-brand-igm" href="#home">IGM Report Portal</Navbar.Brand>
          <span>
            <ButtonToolbar>
                <ButtonGroup>
                    <Form inline className="navbar-form">
                        <CTEPLoginButton user={props.user} handleSignout={props.handleSignout}/>
                    </Form>
                </ButtonGroup>
            </ButtonToolbar>
            </span>
        </Navbar>
  )
}

TopNavBar.propTypes = {
  user: PropTypes.string,
  handleSignout: PropTypes.func
}

export default TopNavBar
