/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import { v4 as uuidv4 } from 'uuid'
import jwtDecode from 'jwt-decode'

function CTEPLoginButton (props) {
  function handleCTEPSAMLToken () {
    const query = new URLSearchParams(window.location.search)

    const token = query.get('token')
    if (token != null) {
      // Found a search token that means we just came back from CTEP SSO. Go ahead and login to the app.
      console.log('Found a search token that means we just came back from CTEP SSO. Go ahead and login to the app.')
      login(token)
    }
  }

  // The [] means that this effect will only be called once, which is what we want for SAML response
  useEffect(handleCTEPSAMLToken, [])

  function createSamlrequestPayload () {
    const requestId = uuidv4()
    const issueInstant = new Date().toISOString()

    let xml = '<?xml version="1.0" encoding="UTF-8"?><samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" AssertionConsumerServiceURL="'
    xml = xml.concat(process.env.REACT_APP_SAML_CALLBACK_URL)
    xml = xml.concat('" Destination="')
    xml = xml.concat(process.env.REACT_APP_CTEP_SSO_URL)
    xml = xml.concat('" ID="')
    xml = xml.concat(requestId)
    xml = xml.concat('" IssueInstant="')
    xml = xml.concat(issueInstant)
    xml = xml.concat('" Version="2.0"><saml:Issuer xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" Format="urn:oasis:names:tc:SAML:2.0:nameid-format:entity">')
    xml = xml.concat(process.env.REACT_APP_SAML_ISSUER)
    xml = xml.concat('</saml:Issuer><md:Extensions xmlns:md="urn:oasis:names:tc:SAML:2.0:metadata"/></samlp:AuthnRequest>')

    return btoa(xml)
  }

  function login (token) {
    const domain = 'developer'
    const expiresIn = 900000 // the time in ms which describes how long the token could live

    const idDecoded = jwtDecode(token)

    const identityId = idDecoded.sub // The Cognito ID is embedded as the subject of the token

    // We need to send a FederatedUser object, but it doesn't actually need to have anything in it.
    const federatedUser = {
      email: '',
      name: ''
    }
    const tokenResponse = {
      identity_id: identityId,
      token: token,
      expires_at: expiresIn * 1000 + new Date().getTime() // the expiration timestamp
    }

    Auth.federatedSignIn(
      domain,
      tokenResponse,
      federatedUser
    ).then(cred => {
      // If success, you will get the AWS credentials
      return Auth.currentAuthenticatedUser()
    }).then(user => {
      // If success, the user object you passed in Auth.federatedSignIn
      // console.log(user);
    }).catch(e => {
      console.log(e)
    })
  }

  function logout () {
    props.handleSignout()
  }

  if (!props.user) {
    const payload = createSamlrequestPayload()
    const actionUrl = process.env.REACT_APP_CTEP_SSO_URL

    return (
        <form action={actionUrl} method="post">
          <input type="hidden" id="saml1" name="SAMLRequest" value={payload}></input>
          <Button type="submit" className='login-button' appearance='primary'>Log In</Button>
        </form>

    )
  } else {
    return (
        <div className='user-pane'>
          <DropdownButton title={props.user.name} id="login-dropdown">
            <Dropdown.Item href="#" onClick={() => logout()}>Log Out</Dropdown.Item>
          </DropdownButton>
        </div>
    )
  }
}

export default CTEPLoginButton
